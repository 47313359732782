<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6"><h2>Pengurusan Kursus</h2></div>
        <div class="col-md-6">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-secondary w-50 me-2"
              data-bs-toggle="modal"
              data-bs-target="#addCourseModal"
            >
              Tambah Kursus +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="courses.length > 0">
      <div class="card-lists">
        <div class="row mx-0 gy-4">
          <div
            class="col col-md-3 col-sm-6"
            v-for="course in courses"
            :key="course.id"
          >
            <div class="card" @click="goto('/admin/courses/' + course.id)">
              <div class="card-body position-relative">
                <span class="position-abolute top-0 float-start">
                  <span class="badge bg-success" v-if="course.is_active"
                    >Aktif</span
                  >
                  <span class="badge bg-secondary" v-else>Tidak Aktif</span>
                </span>
                <span class="position-abolute top-0 float-end">
                  <i class="fas fa-cog"></i>
                </span>
                <div class="position-absolute bottom-0 mb-2">
                  <h5 class="card-title">
                    {{ course.course_name }}
                  </h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    {{ course.course_code }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal"
      id="addCourseModal"
      tabindex="-1"
      aria-labelledby="addCourseModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-7 pe-4">
                <h5>Tambah Kursus Baru</h5>
                <p>
                  Sila tambah kursus mengikut kriteria-kriteria seperti yang
                  tertera.
                </p>

                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Nama Kursus</label
                  >
                  <input
                    id="test"
                    type="text"
                    v-model="courseForm.course_name"
                    class="form-control"
                    placeholder="Kursus Perdana Haji (KPH)"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Kod Kursus</label
                  >
                  <input
                    type="text"
                    v-model="courseForm.course_code"
                    class="form-control"
                    placeholder="KPH"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Tentang Kursus</label
                  >
                  <textarea
                    class="form-control"
                    v-model="courseForm.description"
                    rows="3"
                    placeholder="Penerangan Kursus"
                  ></textarea>
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Tarikh Mula</label
                  >
                  <input
                    type="datetime-local"
                    v-model="courseForm.start_datetime"
                    class="form-control"
                    placeholder="Kursus Perdana Haji (KPH)"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Tarikh Berakhir</label
                  >
                  <input
                    type="datetime-local"
                    v-model="courseForm.end_datetime"
                    class="form-control"
                    placeholder="Kursus Perdana Haji (KPH)"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Jenis Kursus</label
                  >
                  <select class="form-select" v-model="courseForm.course_type">
                    <option selected disabled>Sila pilih</option>
                    <option
                      v-for="item in courseFormOptions.course_types"
                      :key="item.id"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label v-if="labelHasError == true" class="label-error">
                    Sila lengkapkan ruangan ini
                  </label>
                </div>
              </div>
              <div class="col-5 ps-4">
                <h5>Tetapan Kursus</h5>

                <h6 class="mt-3 mb-2">Akses Kepada</h6>
                <div
                  v-for="access_type in user_access_types"
                  :key="access_type.id"
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="courseForm.user_access_types"
                    :value="access_type.id"
                  />
                  <label class="form-check-label">
                    {{ access_type.attributes.label }}
                  </label>
                </div>

                <!-- <div class="form-group mt-3 custom-checkbox">
                  <label for="name" class="control-label mb-1"
                    >Status Kursus</label
                  ><br />
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="courseForm.is_active"
                    id="kuiz"
                  />
                  <label class="form-check-label ms-3" for="kuiz">
                    {{ courseForm.is_active ? "Aktif" : "Tidak aktif" }}
                  </label>
                </div> -->

                <div
                  class="form-group mt-3"
                  v-if="courseForm.course_type && courseForm.course_type == 'live'"
                >
                  <label for="name" class="control-label mb-1">Zoom ID</label>
                  <input
                    type="text"
                    v-model="courseForm.zoom_id"
                    class="form-control"
                    placeholder="Masukkan Zoom ID"
                  />
                </div>
                <div
                  class="form-group mt-3"
                  v-if="courseForm.course_type && courseForm.course_type == 'live'"
                >
                  <label for="name" class="control-label mb-1"
                    >Kata Laluan Zoom</label
                  >
                  <input
                    type="text"
                    v-model="courseForm.zoom_password"
                    class="form-control"
                    placeholder="Masukkan Kata Laluan Zoom"
                  />
                </div>

                <div
                  class="form-group mt-3"
                  v-if="courseForm.course_type && courseForm.course_type == 'live'"
                >
                  <label for="name" class="control-label mb-1"
                    >Kod Embed HTML</label
                  >
                  <textarea
                    id="embed_html"
                    class="form-control"
                    v-model="courseForm.embed_html"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitAddCourse"
            >
              Tambah
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import moment from 'moment';
import $ from "jquery";

export default {
  data() {
    return {
      courses: [],
      labelHasError: false,
      courseForm: {
        course_name: null,
        course_code: null,
        description: null,
        course_type: null,
        role_access: null,
        user_access_types: [],
        is_active: false,
        question_limit: 0,
        answer_time_limit: 0,
        pass_mark_percentage: 0,
        start_datetime: null,
        end_datetime: null,
        embed_html: null,
        zoom_id: null,
        zoom_password: null,
      },
      user_access_types: [],
      courseFormOptions: {
        course_types: [
          { name: "Atas Talian", value: "online" },
          { name: "Siaran Langsung", value: "live" },
        ],
        role_access: [
          { name: "BH - Bakal Haji", value: "bh" },
          { name: "MP - Umum", value: "mp" },
          { name: "PEK - PEKTA", value: "pekta" },
          { name: "Petugas Haji", value: "petugas" },
          { name: "Umum", value: "public" },
        ],
      },
    };
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const res = await API.get("courses?filters[course_type][$eq]=online");
      this.courses = res.data.data
        ? res.data.data.map((o) => {
            return Object.assign({}, { id: o.id }, o.attributes);
          })
        : [];

      const res02 = await API.get(`user-access-types`)
      this.user_access_types = res02.data.data
    },
    async submitAddCourse() {
      this.labelHasError = false;

      if(this.courseForm.course_type != null){
      if (this.courseForm.start_datetime)
        this.courseForm.start_datetime = new Date(this.courseForm.start_datetime).toISOString()
      if (this.courseForm.end_datetime)
        this.courseForm.end_datetime = new Date(this.courseForm.end_datetime).toISOString()

      const res = await API.post('courses', { data: this.courseForm })
      if (res.status == 200)
        this.courses.push(Object.assign({}, res.data.data.attributes, {id: res.data.data.id}))
      $("#addCourseModal").toggle();
      $(".modal-backdrop").remove();}
      else{
        this.labelHasError = true
        console.log('Error: courseForm.course_type is null');
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.label-error{
  color:red;
  padding-top: 2px;
}
</style>
